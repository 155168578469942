import './App.scss';
import {About,Footer,Header,Projects,Skills} from './containers'
import {Navbar} from './components'
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import Messages from './components/Messages'
function App() {
  return (
    <div className='app'>
      <Navbar />
      <Header />
      <About />
      <Skills />
      <Projects />
      <Footer />
    </div>
  );
}

const AppRouter=()=>{
  return(
    <Router>
    <Routes>
      <Route path='/' element={<App />} />
      <Route path='/my-messages' element={<Messages/>} />
      <Route path='*' element={<App />} />
    </Routes>
  </Router>
  )

}

export default AppRouter;
