import React, { useState } from 'react'
import { useEffect } from 'react'
import {client} from '../client'
import './Messages.scss'
const Messages = () => {
    const [key, setKey] = useState('')
    const [validKey, setValidKey] = useState(false)
    const [popUp, setPopUp] = useState(false)
    const [messages, setMessages] = useState([])

    useEffect(()=>{
        const query='*[_type=="contact"]'
        client.fetch(query)
        .then((data)=>setMessages(data))
    },[])

    const validate=()=>{
        if (key===process.env.REACT_APP__MESSAGE_KEY) {
            setValidKey(true)
        }
        else{
            setPopUp(true)
            setTimeout(()=>{
                setPopUp(false)
            },3000)
        }
    }
  return (
    <div className='app'>
    {!validKey?(
        <main className='app__messages app__flex'>
        <h2 className="head-text">Welcome Admin, enter your password!</h2>
        <input type="password" placeholder='password'
        value={key} onChange={(e)=>setKey(e.target.value)}
        />
        <button onClick={validate} className='primary-btn'>Submit</button>
        {popUp &&
            <p className="p-text">Looks like the password is incorrect, try again</p>
        }
        </main>
    )
    :(
    <main className="app__messages-display app__flex">
        <h2 className="head-text">Your messages</h2>
        <div className="app__messages-messages app__flex">
        {messages.map((message,index)=>(
        <article key={index+message} className="app__messages-message app__flex">
        <div className="app__messages-detail app__flex">
        <h4 className="head-text">{message.name}</h4>
        <p className="p-text">({message.email})</p>
        </div>
        <p  className="body p-text">{message.body}</p>
        </article>
        ))

        }
        </div>
    </main>
    )
    }
    </div>
  )
}

export default Messages